<div id="goBackBtn" (click)="goBack()" *ngIf="isBackRoute()">
  <div id="icon">
    <mat-icon>arrow_back_ios</mat-icon>
  </div>
</div>
<div id="blackBackground" *ngIf="event">
  <div id="background" style="--category-image-url: url('{{ apiEndpoint }}{{ event.category_image }}');" *ngIf="event.id !== -1"></div>
  <div id="eventCard" style="--image-url: url('{{ apiEndpoint }}{{ event.image }}');">
    <div id="firstRow">
      <div id="titleGroup">
        <h1 class="title">{{ event.name }}</h1>
        <img class="verifiedImage" src="/assets/icons/verified.svg" *ngIf="event.is_verified" id="verifiedIcon" alt="verfiedIcon" />
      </div>
      <button mat-raised-button (click)="toCalendar()" color="accent" id="dateButton">{{ dateWeekday(event.start_time) }} {{ event.start_time | date : 'dd' }}. {{ dateMonth(event.start_time) }}</button>
    </div>
    <div id="eventPic"></div>

    <div id="infos">
      <div class="factgroup">
        <div class="row">
          <img class="icon" src="/assets/icons/calendar_clock.svg" alt="clock" />
          <h3>Datum</h3>
        </div>
        <div class="row flex-space-between">
          <div>{{ event.start_time | date : 'dd.MM.yy' }}</div>
          <div class="bold">{{ event.start_time | date : 'HH:mm' }}</div>
        </div>
        <div class="row">bis</div>
        <div class="row flex-space-between">
          <div>{{ event.end_time | date : 'dd.MM.yy' }}</div>
          <div class="bold">{{ event.end_time | date : 'HH:mm' }}</div>
        </div>
      </div>
      <div class="factgroup">
        <div class="row">
          <img class="icon" src="/assets/icons/location_on.svg" alt="location icon" />
          <h3>Location</h3>
        </div>
        <div class="row bold">{{ event.location_name }}</div>
        <div class="row">{{ place.street }}</div>
        <div class="row">{{ place.city }}</div>
      </div>
      <div class="factgroup" *ngIf="organizer">
        <div class="row">
          <img class="icon" src="/assets/icons/flag.svg" alt="flag icon" />
          <h3>Veranstalter</h3>
        </div>
        <div class="row bold">{{ organizer.name }}</div>
        <div class="row" *ngIf="organizer.link">
          <p id="organizerLink">{{ transformLink(organizer.link) }}</p>
        </div>
        <div class="row">
          <a id="viewmore" href="{{ organizer.link }}" target="_blank">mehr anzeigen</a>
        </div>
      </div>
    </div>

    <div id="description" [innerHTML]="descriptionMarkdown"></div>
  </div>
</div>
<app-not-found *ngIf="showNotFound"></app-not-found>