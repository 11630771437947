import { Injectable, Inject } from '@angular/core';
import { NavigationStart, Router, Event } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LoadingStateService {
  public loading: boolean = false;
  private timeout: ReturnType<typeof setInterval> | undefined = undefined;

  constructor(@Inject(Router) private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.activate();
      }
    });
  }

  activate() {
    this.resetTimeout();
    this.timeout = setTimeout(() => {
      this.loading = true;
    });
  }

  deactivate() {
    this.resetTimeout();
    this.timeout = setTimeout(() => {
      this.loading = false;
    });
  }

  resetTimeout() {
    clearTimeout(this.timeout);
  }
}
