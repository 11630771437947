import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FullCalendarModule } from '@fullcalendar/angular';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';

import { provideNativeDateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';

import { NavbarComponent } from './navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { EventNormalComponent } from './event-normal/event-normal.component';
import { EventFeaturedComponent } from './event-featured/event-featured.component';
import { EventSpecialComponent } from './event-special/event-special.component';
import { EventsComponent } from './events/events.component';
import { EventDetailViewComponent } from './event-detail-view/event-detail-view.component';
import { AddEventFormComponent } from './add-event-form/add-event-form.component';
import { LocationPickerMapComponent } from './location-picker-map/location-picker-map.component';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
import { CalendarComponent } from './calendar/calendar.component';
import { LoginComponent } from './login/login.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminNavbarComponent } from './admin-navbar/admin-navbar.component';
import { AdminDashboardSpecialEventComponent } from './admin-dashboard-special-event/admin-dashboard-special-event.component';
import { AdminDashboardFeaturedEventComponent } from './admin-dashboard-featured-event/admin-dashboard-featured-event.component';
import { AdminDashboardValidateEventsComponent } from './admin-dashboard-validate-events/admin-dashboard-validate-events.component';
import { AdminDashboardValidateOrganizersComponent } from './admin-dashboard-validate-organizers/admin-dashboard-validate-organizers.component';
import { AddOrganizerFormComponent } from './add-organizer-form/add-organizer-form.component';
import { MatSliderModule } from '@angular/material/slider';
import { FooterComponent } from './footer/footer.component';
import { OrganizerComponent } from './organizer/organizer.component';
import { OrganizersComponent } from './organizers/organizers.component';
import { AdminDashboardEditEventsComponent } from './admin-dashboard-edit-events/admin-dashboard-edit-events.component';
import { AdminDashboardEditOrganizersComponent } from './admin-dashboard-edit-organizers/admin-dashboard-edit-organizers.component';
import { ValidateOrganizerComponent } from './validate-organizer/validate-organizer.component';
import { ValidateEventComponent } from './validate-event/validate-event.component';
import { EditEventComponent } from './edit-event/edit-event.component';
import { EditOrganizerComponent } from './edit-organizer/edit-organizer.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { NotFoundComponent } from './not-found/not-found.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    EventNormalComponent,
    EventFeaturedComponent,
    EventSpecialComponent,
    EventsComponent,
    EventDetailViewComponent,
    AddEventFormComponent,
    LocationPickerMapComponent,
    ImageCropperComponent,
    CalendarComponent,
    LoginComponent,
    AdminDashboardComponent,
    AdminNavbarComponent,
    AdminDashboardSpecialEventComponent,
    AdminDashboardFeaturedEventComponent,
    AdminDashboardValidateEventsComponent,
    AdminDashboardValidateOrganizersComponent,
    AddOrganizerFormComponent,
    FooterComponent,
    OrganizersComponent,
    OrganizerComponent,
    AdminDashboardEditEventsComponent,
    AdminDashboardEditOrganizersComponent,
    ValidateOrganizerComponent,
    ValidateEventComponent,
    EditEventComponent,
    EditOrganizerComponent,
    DeleteDialogComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    BrowserAnimationsModule,
    MatChipsModule,
    MatProgressBarModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatExpansionModule,
    ImageCropperModule,
    MatDialogModule,
    MatCardModule,
    NgxMatTimepickerModule.setLocale(
      'de-AT',
    ),
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatSelectModule,
    HttpClientModule,
    FullCalendarModule,
    MatMenuModule,
    MatSliderModule,
    MatTableModule,
  ],
  exports: [
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    BrowserAnimationsModule,
  ],
  providers: [
    provideNativeDateAdapter(),
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'de-AT',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeDe, 'de-DE', localeDeExtra);
  }
}
