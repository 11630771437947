import { Component, ElementRef, ViewChild } from '@angular/core';
import { LoadingStateService } from '../loading-state.service';
import { AbstractControl, FormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { pluck } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';
import { NewOrganizer } from 'src/assets/organizer.dto';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

interface previewTableElement {
  key: string;
  value: string;
}

@Component({
  selector: 'app-add-organizer-form',
  templateUrl: './add-organizer-form.component.html',
  styleUrl: './add-organizer-form.component.scss',
})
export class AddOrganizerFormComponent {
  visitedPages: number[] = [];
  @ViewChild('stepper', { static: false }) private stepper: MatStepper | undefined;
  filepath: string = '';
  imageSrc: SafeUrl = '';
  @ViewChild('imageupload', { static: false }) imageUploadInput: ElementRef | undefined;
  existingOrganizers: string[] = [];
  
  generalGroup = this.formBuilder.group({
    organizername: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50), this.organizerNameValidator.bind(this)]],
    description: ['', [Validators.required, Validators.maxLength(6000)]],
  });
  
  locationGroup = this.formBuilder.group({
    location: ['', Validators.required],
    address: ['', Validators.required],
    latitude: [-1, Validators.required],
    longitude: [-1, Validators.required],
  });
  
  contentGroup = this.formBuilder.group({
    locationLink: ['', [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'), Validators.maxLength(200)]],
    croppedImage: [new Blob()],
  });
  
  previewTableData: previewTableElement[] = [];
  previewTableColumns: string[] = ['key', 'value'];

  createButtonActive: boolean = true;

  constructor(private loadingStateService: LoadingStateService, private formBuilder: FormBuilder, public dialog: MatDialog, private sanitizer: DomSanitizer, private api: ApiService, private router: Router, private snackBar: MatSnackBar) {}
  
  async ngOnInit() {
    await this.api.fetchVerifiedOrganizers();
    this.existingOrganizers = this.api.getVerifiedOrganizers().map((organizer) => organizer.name);
    this.loadingStateService.deactivate();
  }

  ngAfterViewInit() {
    this.stepper?.selectionChange.pipe(pluck('selectedIndex')).subscribe((res: number) => {
      if (!this.visitedPages.includes(res)) {
        this.visitedPages.push(res);
      }
      if (res === 3) {
        this.generatePreviewTable();
      }
    });
  }

  organizerNameValidator(control: AbstractControl): ValidationErrors | null {
    if (this.existingOrganizers.includes(control.value)) {
      return { organizerNameExists: true };
    }
    return null;
  }

  mapLocationChanged(e: { latitude: number; longitude: number; address: string }) {
    this.locationGroup.controls['latitude'].setValue(e.latitude);
    this.locationGroup.controls['longitude'].setValue(e.longitude);
    this.locationGroup.controls['address'].setValue(e.address.replaceAll(', ', '\n'));
  }

  fileChangeEvent(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.files === null) {
      return;
    }

    this.filepath = target.files[0].name;
    const dialogRef = this.dialog.open(ImageCropperComponent, {
      data: event,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.contentGroup.controls['croppedImage'].setValue(result.blob);
      this.imageSrc = this.sanitizer.bypassSecurityTrustUrl(result.objectUrl);
    });
  }

  imageIsUnchanged() {
    return this.imageSrc === '';
  }

  generatePreviewTable() {
    this.previewTableData = [];

    if (this.generalGroup.controls['organizername'].value) {
      this.previewTableData.push({ key: 'Veranstaltername', value: this.generalGroup.controls['organizername'].value });
    }

    if (this.generalGroup.controls['description'].value) {
      this.previewTableData.push({ key: 'Beschreibung', value: this.generalGroup.controls['description'].value });
    }

    if (this.locationGroup.controls['location'].value) {
      this.previewTableData.push({ key: 'Location', value: this.locationGroup.controls['location'].value });
    }

    if (this.locationGroup.controls['address'].value) {
      this.previewTableData.push({ key: 'Addresse', value: this.locationGroup.controls['address'].value });
    }

    if (this.contentGroup.controls['locationLink'].value) {
      this.previewTableData.push({ key: 'Location Link', value: this.contentGroup.controls['locationLink'].value });
    }
  }

  async submitForm() {
    this.createButtonActive = false;
    this.snackBar.open('Veranstalter wird erstellt...', '', {
      panelClass: ['gray-snackbar'],
    });
    let name = '';
    let description = '';
    let image: Blob | null = null;
    let link = '';
    let location_name = '';
    let latitude = -1;
    let longitude = -1;

    if (this.generalGroup.controls['organizername'].value !== null && this.generalGroup.controls['organizername'].value !== '') {
      name = this.generalGroup.controls['organizername'].value;
    }
    if (this.imageSrc !== '') {
      image = this.contentGroup.controls['croppedImage'].value;
    }
    if (this.generalGroup.controls['description'].value !== null && this.generalGroup.controls['description'].value !== '') {
      description = this.generalGroup.controls['description'].value;
    }
    if (this.contentGroup.controls['locationLink'].value !== null && this.contentGroup.controls['locationLink'].value !== '') {
      link = this.contentGroup.controls['locationLink'].value;
    }
    if (this.locationGroup.controls['location'].value !== null && this.locationGroup.controls['location'].value !== '') {
      location_name = this.locationGroup.controls['location'].value;
    }
    if (this.locationGroup.controls['latitude'].value !== null && this.locationGroup.controls['latitude'].value !== -1) {
      latitude = this.locationGroup.controls['latitude'].value;
    }
    if (this.locationGroup.controls['longitude'].value !== null && this.locationGroup.controls['longitude'].value !== -1) {
      longitude = this.locationGroup.controls['longitude'].value;
    }

    const organizer: NewOrganizer = {
      name: name,
      description: description,
      link: link,
      location_name: location_name,
      latitude: latitude,
      longitude: longitude,
    };

    if (await this.api.postOrganizer(organizer, image)) {
      this.snackBar.open('Veranstalter erfolgreich erstellt', '', {
        duration: 3000,
        panelClass: ['green-snackbar'],
      });
      this.router.navigate(['/']);
    } else {
      this.snackBar.open('Fehler beim Erstellen des Veranstalters', '', {
        duration: 3000,
        panelClass: ['red-snackbar'],
      });
    }
    this.createButtonActive = true;
  }
}
