<div id="background">
    <div id="logoDiv">
        <img id="logo" src="assets/logo_round.svg" alt="logo">
        <div id="copyright">© 2024 twenty-one-events.htl-perg.at</div>
    </div>
    <div id="box1">
        <div id="addEvent"  (click)="toRoute('new')"><span class="hoverable">Event eintragen</span></div>
        <div id="addOrganizer" (click)="toRoute('new-organizer')"> <span class="hoverable">Veranstalter eintragen</span></div>
        <div id="login"  (click)="toRoute('login')"><span class="hoverable">Admin-Dashboard</span></div>
    </div>
    <div id="box2">
        <div id="imprint" (click)="toRoute('imprint')"><span class="hoverable">Impressum</span></div>
        <div id="DSGVO" (click)="toRoute('imprint')"><span class="hoverable">DSGVO</span></div>
    </div>
</div>
