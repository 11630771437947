export interface EventDto {
  id: number;
  organizer: number | null;
  name: string;
  short_description: string;
  description: string;
  validated: boolean;
  is_verified: boolean;
  image: string;
  category_image: string;
  category: string;
  link: string | null;
  start_time: Date;
  end_time: Date;
  location_name: string;
  latitude: number;
  longitude: number;
  created: Date;
  updated: Date;
}

export const dummyEventDto: EventDto = {
  id: -1,
  organizer: null,
  name: '',
  short_description: '',
  description: '',
  validated: false,
  is_verified: false,
  image: '/static/defaults/default-image.png',
  category_image: '',
  category: '',
  link: '',
  start_time: new Date(),
  end_time: new Date(),
  location_name: '',
  latitude: -1,
  longitude: -1,
  created: new Date(),
  updated: new Date(),
};

export interface NewEvent {
  organizer: number | null;
  name: string;
  short_description: string;
  description: string;
  category: string;
  link: string | null;
  start_time: Date;
  end_time: Date;
  location_name: string;
  latitude: number;
  longitude: number;
}