<div id="content">
  <h2 id="heading">Events</h2>
  <div id="eventList">
    <div id="events">
      <div id="searchBox">
        <div id="group">
          <mat-form-field class="searchfield">
            <mat-label>Suchbegriff eingeben..</mat-label>
            <input matInput [(ngModel)]="searchQuery" (input)="autoSearcher()" />
            @if (searchQuery) {
            <button matSuffix mat-icon-button aria-label="Clear" (click)="resetSearch()">
              <mat-icon>close</mat-icon>
            </button>
            }
          </mat-form-field>
          <button mat-raised-button color="accent" id="searchbtn" (click)="runSearch()">Suchen</button>
        </div>

        <div id="checkFilterContainer">
          @for (item of searchSuggestions; track $index) {
          <button mat-raised-button color="accent" class="suggestionBut" (click)="setSearchQuery(item)">{{ item }}</button>
          }
        </div>
      </div>
      <div id="filterBox">
        <h1>Kategorie</h1>
        <div id="multiSelection">
          <mat-form-field id="selectCat">
            <mat-label>auswählen</mat-label>
            <mat-select [formControl]="selectedCategorys" multiple>
              @for (category of categoryList; track category) {
              <mat-option [value]="category.value" (click)="categoryChange()">{{ category.name }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div id="slider">
          <h1>Umkreis</h1>
          <mat-slider color="primary" discrete [displayWith]="formatLabel" id="rangeSlider" (slideend)="runSearch()" (pointerup)="runSearch()" min="1" max="101">
            <input [(ngModel)]="sliderValue" matSliderThumb />
          </mat-slider>
        </div>
        <button mat-raised-button color="primary" id="resetFilter" (click)="resetFilter()">zurücksetzen</button>
      </div>
      <app-event-normal class="event" *ngFor="let event of events" [event]="event"></app-event-normal>
      <div *ngIf="events.length === 0"></div>
      <div id="allDone" *ngIf="events.length === 0">
        <mat-icon>error</mat-icon>
        <div>Keine Events gefunden!</div>
      </div>
    </div>
  </div>
</div>
