import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventDto } from 'src/assets/event.dto';

interface LoginResponse {
  refresh: string;
  access: string;
  status: number;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  
  endpoint = 'https://api.twenty-one-events.htl-perg.ac.at';
  refreshToken = '';
  accessToken = '';
  username = '';
  cookieCycle: ReturnType<typeof setInterval> | undefined = undefined;
  
  constructor(private http: HttpClient) {}
  
  init() {
    if (this.loadFromLocalStorage()) {
      this.startRefreshCycle();
    } else {
      this.logout();
    }
  }

  async login(username: string, password: string): Promise<boolean> {
    try {
      const response: LoginResponse = (await this.http.post(`${this.endpoint}/token/`, { username, password }).toPromise()) as LoginResponse;
      if (response.status) return false;
      this.refreshToken = response['refresh'];
      this.accessToken = response['access'];
      const decoded = JSON.parse(atob(this.accessToken.split('.')[1]));
      this.username = decoded['username'];
      this.saveToLocalStorage();
      this.startRefreshCycle();
      return true;
    } catch (error) {
      return false;
    }
  }

  logout() {
    this.refreshToken = '';
    this.accessToken = '';
    this.username = '';
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('username');
    if (this.cookieCycle) clearInterval(this.cookieCycle);
  }

  isLoggedIn(): boolean {
    return this.accessToken !== '';
  }

  setFeaturedEvent(eventId: number): Promise<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.accessToken}`,
    });
    return this.http.post(`${this.endpoint}/events/featuredevent/`, { id: eventId }, { headers }).toPromise();
  }

  setSpecialEvent(eventId: number): Promise<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.accessToken}`,
    });
    return this.http.post(`${this.endpoint}/events/specialevent/`, { id: eventId }, { headers }).toPromise();
  }

  async getUnvalidatedEvents(): Promise<EventDto[]> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.accessToken}`,
    });
    return (await this.http.get(`${this.endpoint}/management/events/notvalid`, { headers }).toPromise()) as EventDto[];
  }

  async getUnvalidatedOrganizers(): Promise<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.accessToken}`,
    });
    return (await this.http.get(`${this.endpoint}/management/organizers/notvalid`, { headers }).toPromise()) as any;
  }

  async toggleEventValidation(eventId: number): Promise<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.accessToken}`,
    });
    return (await this.http.post(`${this.endpoint}/management/events/validate/${eventId}/`, null, { headers }).toPromise()) as any;
  }

  async toggleEventVerification(eventId: number): Promise<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.accessToken}`,
    });
    return (await this.http.post(`${this.endpoint}/management/events/verify/${eventId}/`, null, { headers }).toPromise()) as any;
  }

  async toggleOrganizerValidation(organizerId: number): Promise<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.accessToken}`,
    });
    return (await this.http.post(`${this.endpoint}/management/organizers/validate/${organizerId}/`, null, { headers }).toPromise()) as any;
  }

  async toggleOrganizerVerification(organizerId: number): Promise<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.accessToken}`,
    });
    return (await this.http.post(`${this.endpoint}/management/organizers/verify/${organizerId}/`, null, { headers }).toPromise()) as any;
  }

  async deleteEvent(eventId: number): Promise<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.accessToken}`,
    });
    return this.http.delete(`${this.endpoint}/events/${eventId}/`, { headers }).toPromise();
  }

  async deleteOrganizer(organizerId: number): Promise<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.accessToken}`,
    });
    return this.http.delete(`${this.endpoint}/organizers/${organizerId}/`, { headers }).toPromise();
  }

  async startRefreshCycle() {
    if (this.cookieCycle) clearInterval(this.cookieCycle);
    await this.renewToken();
    this.cookieCycle = setInterval(async () => {
      await this.renewToken();
    }, 1000 * 60 * 3);
  }

  async renewToken() {
    if (!this.refreshToken) return;
    try {
      const response: LoginResponse = (await this.http.post(`${this.endpoint}/token/refresh/`, { refresh: this.refreshToken }).toPromise()) as LoginResponse;
      this.accessToken = response['access'];
      this.refreshToken = response['refresh'];
      this.saveToLocalStorage();
    } catch (error) {
      this.logout();
    }
  }

  saveToLocalStorage() {
    localStorage.setItem('refreshToken', this.refreshToken);
    localStorage.setItem('accessToken', this.accessToken);
    localStorage.setItem('username', this.username);
  }

  loadFromLocalStorage(): boolean {
    if (!localStorage.getItem('refreshToken')) return false;
    this.refreshToken = localStorage.getItem('refreshToken') as string;
    this.accessToken = localStorage.getItem('accessToken') as string;
    this.username = localStorage.getItem('username') as string;
    return this.isLoggedIn();
  }
}
