import { Component } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import { LoadingStateService } from '../loading-state.service';
import { ApiService } from '../api.service';
import { EventDto } from 'src/assets/event.dto';


interface CalendarOptionEvent {
  title: string;
  start: Date;
  end: Date;
  url: string;
}

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.scss',
})
export class CalendarComponent {
  constructor(private loadingStateService: LoadingStateService, private api: ApiService) {}

  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin],
    initialView: 'dayGridMonth',
    events: [{}],
    eventTimeFormat: {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    },
    locale: 'de',
    firstDay: 1,
  };
  async ngOnInit() {
    this.loadingStateService.deactivate();
    this.calendarOptions.events = await this.loadEvents();
  }

  async loadEvents() {
    await this.api.fetchVerifiedEvents();
    const events: EventDto[] = this.api.getVerifiedEvents();
    const calendarEvents: CalendarOptionEvent[] = [];

    for (const event of events) {
      calendarEvents.push({
        title: event.name,
        start: event.start_time,
        end: event.end_time,
        url: `/event/${event.id}`,
      });
    }
    return calendarEvents;
  }
}
