import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-admin-navbar',
  templateUrl: './admin-navbar.component.html',
  styleUrl: './admin-navbar.component.scss'
})
export class AdminNavbarComponent {
  navbarOpen = false;
  readyOpen = false;
  blockNav = false;
  hamburgerIcon = '/assets/icons/hamburger.svg';
  username = this.authService.username;

  constructor(private router: Router, private authService: AuthService) {}

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async toggleNavbar() {
    if (this.blockNav) {
      return;
    }
    this.blockNav = true;
    if (this.navbarOpen) {
      await this.closeNavbar();
    } else {
      await this.openNavbar();
    }
    this.blockNav = false;
  }

  async closeNavbar() {
    this.hamburgerIcon = '/assets/icons/hamburger.svg';
    this.readyOpen = false;
    await this.delay(150);
    this.navbarOpen = false;
    this.blockNav = false;
  }

  async openNavbar() {
    this.hamburgerIcon = '/assets/icons/hamburgerclose.svg';
    this.navbarOpen = true;
    await this.delay(300);
    this.readyOpen = true;
    this.blockNav = false;
  }
  
  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  toRoute(route: string) {
    const routerUrl = '/' + route;
    if (this.router.url !== routerUrl) {
      this.router.navigate(['/' + route]);
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    this.closeNavbar();
  }
}
