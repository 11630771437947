import { Component } from '@angular/core';
import { LoadingStateService } from '../loading-state.service';
import { AuthService } from '../auth.service';
import { Organizer } from 'src/assets/organizer.dto';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-admin-dashboard-validate-organizers',
  templateUrl: './admin-dashboard-validate-organizers.component.html',
  styleUrl: './admin-dashboard-validate-organizers.component.scss'
})
export class AdminDashboardValidateOrganizersComponent {
  constructor(private loadingStateService: LoadingStateService, private auth: AuthService, private api: ApiService) {}
  organizers: Organizer[] = [];
  status: number[] = [];
  loading: boolean = true;
  
  async ngOnInit() {
    this.organizers = await this.auth.getUnvalidatedOrganizers();
    this.status = this.organizers.map(() => 0);
    this.loadingStateService.deactivate();
    this.loading = false;
  }

  async toggleEvent(index: number) {
    if (this.status[index] === 1) return;
    const newStatus = this.status[index] === 0 ? 2 : 0;
    this.status[index] = 1;
    await this.auth.toggleOrganizerValidation(this.organizers[index].id);
    this.status[index] = newStatus;
    this.api.fetchVerifiedOrganizers(true);
  }
}
