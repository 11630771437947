import { Component } from '@angular/core';
import { LoadingStateService } from '../loading-state.service';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  hidePwd = true;
  username = '';
  password = '';

  constructor(private authService: AuthService, private loadingStateService: LoadingStateService, private router: Router, private snackBar: MatSnackBar) {}

  async ngOnInit() {
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/admin-dashboard']);
    }
    this.loadingStateService.deactivate();
  }

  async login() {
    this.loadingStateService.activate();
    const loggedInSuccess = await this.authService.login(this.username, this.password);
    this.loadingStateService.deactivate();
    if (loggedInSuccess) {
      this.router.navigate(['/admin-dashboard']);
    } else {
      this.password = '';
      this.showLoginFailed();
    }
  }

  userTyping() {
    this.snackBar.dismiss();
  }

  showLoginFailed() {
    this.snackBar.open('Login fehlgeschlagen!', '', {
      duration: 3000,
      panelClass: ['red-snackbar'],
    });
  }
}
