<div id="wrapper">
  <div id="left">
    <div id="titleWrapper">
      <div id="title">
        <h1>LOGIN</h1>
        <div id="subtext">21Events - Moderation</div>
      </div>
    </div>
    <div id="loginWrapper">
      <div id="username">
        <mat-form-field appearance="fill">
          <mat-label>Benutzername</mat-label>
          <input matInput [(ngModel)]="username" (input)="userTyping()" />
          <mat-icon matSuffix>person</mat-icon>
        </mat-form-field>
      </div>

      <div id="password">
        <mat-form-field>
          <mat-label>Passwort</mat-label>
          <input matInput [type]="hidePwd ? 'password' : 'text'" [(ngModel)]="password" (input)="userTyping()" />
          <button mat-icon-button matSuffix (click)="hidePwd = !hidePwd" [attr.aria-label]="'Passwort verstecken'" [attr.aria-pressed]="hidePwd">
            <mat-icon>{{ hidePwd ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
        </mat-form-field>
      </div>

        <div id="loginBtnWrapper">
          <button id="loginBtn" mat-raised-button color="accent" (click)="login()">Einloggen</button>
        </div>
        
      </div>
      <div id="notice">
        <div id="noticebox">
          <div id="icon"><mat-icon>info</mat-icon></div>
          <div>Du kannst dich unter <a href="mailto:mail@21events.at">mail&commat;21events.at</a> als Moderator bewerben.</div>
        </div>
      </div>
  </div>
  <div id="right"></div>
</div>
