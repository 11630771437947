import { Component } from '@angular/core';
import { LoadingStateService } from '../loading-state.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss'
})
export class NotFoundComponent {
  constructor(private loadingStateService: LoadingStateService, private router: Router) {}

  ngAfterViewInit() {
    this.loadingStateService.deactivate();
  }

  toHome() {
    this.router.navigate(['/']);
  }
}
