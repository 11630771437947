import {
  Component,
  ElementRef,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { environment } from '../../environments/environment';
import * as mapboxgl from 'mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

@Component({
  selector: 'app-location-picker-map',
  templateUrl: './location-picker-map.component.html',
  styleUrl: './location-picker-map.component.scss',
})
export class LocationPickerMapComponent {
  @ViewChild('map', { static: true }) mapElement?: ElementRef;

  @Output() locationPicked = new EventEmitter<{
    latitude: number;
    longitude: number;
    address: string;
  }>();
  marker: mapboxgl.Marker | undefined = undefined;
  map: mapboxgl.Map | undefined = undefined;
  geocoder: MapboxGeocoder | undefined = undefined;

  ngOnInit() {
    mapboxgl!.accessToken = environment.mapBoxApiKey;

    this.map = new mapboxgl.Map({
      container: this.mapElement?.nativeElement,
      style: 'mapbox://styles/mapbox/outdoors-v12',
      center: [13.25325447676151, 47.66770578272795],
      zoom: 6,
    });

    this.geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      language: 'de-AT',
      mapboxgl: mapboxgl,
      marker: false,
      zoom: 16,
    });

    this.map.addControl(this.geocoder);

    this.map.on('load', () => this.updateGeocoderProximity());
    this.map.on('moveend', () => this.updateGeocoderProximity());

    this.map.on('click', (e: mapboxgl.EventData) =>
      this.mapClickHandler(e['lngLat'])
    );

    this.geocoder.on('result', (e: mapboxgl.EventData) => {
      this.mapClickHandler(e['result'].geometry.coordinates);
    });
  }

  updateGeocoderProximity() {
    const center = this.map!.getCenter().wrap();
    if (this.map!.getZoom() > 9) {
      this.geocoder!.setProximity({
        longitude: center.lng,
        latitude: center.lat,
      });
    } else {
      this.geocoder!.setProximity(
        { longitude: center.lng, latitude: center.lat },
        true
      );
    }
  }

  async mapClickHandler(coordinates: mapboxgl.LngLat) {
    if (this.marker === undefined) {
      this.marker = new mapboxgl.Marker()
        .setLngLat(coordinates)
        .addTo(this.map!);
    } else {
      this.marker.setLngLat(coordinates);
    }
    this.locationPicked.emit({
      latitude: this.marker.getLngLat().lat,
      longitude: this.marker.getLngLat().lng,
      address: await this.getAdress(new mapboxgl.LngLat(this.marker.getLngLat().lng, this.marker.getLngLat().lat)),
    });
  }

  async getAdress(coordinates: mapboxgl.LngLat) {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${coordinates.lng},${coordinates.lat}.json?access_token=${mapboxgl.accessToken}&language=de-AT&types=address&limit=1`;
    const response = await (await fetch(url)).json();
    try {
      return response.features[0].place_name;
    } catch (error) {
      return '';
    }
  }
}
