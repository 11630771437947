<div id="event" style="--background-image-url: url('{{apiEndpoint}}{{event.image}}')" (click)="toEventDetailView()">
  <div id="infos">
    <div id="left">
      <div id="name">{{ event.name }}</div>
      <div id="location">
        <img src="/assets/icons/mapicon.svg" alt="Map Icon" />
        {{ event.location_name }}
      </div>
    </div>
    <div id="right">
      <div id="date">
        <div>{{ dateWeekday(event.start_time) }}</div>
        <div>{{ event.start_time | date : 'dd.MM.yy' }}</div>
      </div>
    </div>
  </div>
  
  <div *ngIf="event.is_verified" id="verifiedBadge">
    <img src="/assets/icons/verified_badge.png" alt="Verifiziertes Event">
  </div>
</div>
