import { Component } from '@angular/core';
import { LoadingStateService } from '../loading-state.service';
import { ApiService } from '../api.service';
import { EventDto, dummyEventDto } from 'src/assets/event.dto';
import { FormControl } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { AuthService } from '../auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-admin-dashboard-special-event',
  templateUrl: './admin-dashboard-special-event.component.html',
  styleUrl: './admin-dashboard-special-event.component.scss',
})
export class AdminDashboardSpecialEventComponent {
  selectControl = new FormControl('');
  options: EventDto[] = [];
  filteredOptions: Observable<EventDto[]> | undefined;
  currentSpecialEvent: EventDto = dummyEventDto;

  constructor(private loadingStateService: LoadingStateService, private api: ApiService, private auth: AuthService, private snackBar: MatSnackBar) {}

  async ngOnInit() {
    await this.loadEvents();
    this.loadingStateService.deactivate();
  }

  async loadEvents(force: boolean = false) {
    await this.api.fetchRecommendedEvents(force);
    this.currentSpecialEvent = this.api.getSpecialEvent()!;

    await this.api.fetchVerifiedEvents(force);
    this.options = this.api.getVerifiedEvents().filter((event) => event.id !== this.currentSpecialEvent.id);

    this.filteredOptions = this.selectControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filter(value || '')),
    );
  }

  private filter(value: string): EventDto[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) => option.name.toLowerCase().includes(filterValue));
  }

  onSelectionChanged(event: any) {
    const eventSelected: EventDto = JSON.parse(event.option.id);
    this.currentSpecialEvent = eventSelected;
  }

  async save() {
    this.loadingStateService.activate();
    try {
      await this.auth.setSpecialEvent(this.currentSpecialEvent.id);
    } catch (e) {
      this.loadingStateService.deactivate();
      this.snackBar.open('Speichern fehlgeschlagen!', '', {
        duration: 3000,
        panelClass: ['red-snackbar'],
      });
      return;
    }
    this.loadingStateService.deactivate();
    this.snackBar.open('Neues Special Event wurde gespeichert', '', {
      duration: 3000,
      panelClass: ['green-snackbar'],
    });
    this.selectControl.setValue('');
    this.loadEvents(true);
  }

  saveButtonActive(): boolean {
    return this.currentSpecialEvent.id !== this.api.getSpecialEvent()?.id;
  }
}
