import { Component, Input, input } from '@angular/core';
import { Organizer, dummyOrganizer } from 'src/assets/organizer.dto';
import { ApiService } from '../api.service';
import { LoadingStateService } from '../loading-state.service';
import { dateWeekday } from 'src/assets/translaters';
import { Router } from '@angular/router';

@Component({
  selector: 'app-organizer',
  templateUrl: './organizer.component.html',
  styleUrl: './organizer.component.scss',
})
export class OrganizerComponent {
  apiEndpoint = '';
  @Input() organizer: Organizer = dummyOrganizer;

  constructor(private api: ApiService, private router: Router){}

  ngOnInit() {
    this.apiEndpoint = this.api.getEndpoint();
  }

  dateWeekday(date: Date) {
    return dateWeekday(date);
  }

  routeToEvent() {
    if (!this.organizer.next_event) return;
    this.router.navigate(['/event', this.organizer.next_event.id]);
  }
}
