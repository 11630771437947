import { Component } from '@angular/core';
import { Organizer } from 'src/assets/organizer.dto';
import { ApiService } from '../api.service';
import { LoadingStateService } from '../loading-state.service';

@Component({
  selector: 'app-organizers',
  templateUrl: './organizers.component.html',
  styleUrl: './organizers.component.scss'
})
export class OrganizersComponent {
  organizers: Organizer[] = [];

  constructor(private api: ApiService, private loadingStateService: LoadingStateService) {}

  async ngOnInit() {
    await this.api.fetchVerifiedOrganizers();
    this.organizers = this.api.getVerifiedOrganizers();
    this.loadingStateService.deactivate();
    console.log(this.organizers);
  }
}
