<h2 mat-dialog-title>Zuschneiden</h2>
<mat-dialog-content>
    <div id="imageCropper">
      <mat-spinner color="accent" *ngIf="this.loading"></mat-spinner>
      <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="8 / 5"
        format="jpeg"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded($event)"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions id="actions">
    <button mat-raised-button [mat-dialog-close]="croppedImage" color="primary">Fertig</button>
  </mat-dialog-actions>