<mat-toolbar [ngClass]="{ open: navbarOpen, opened: readyOpen }">
  <div class="part logo">
    <img src="../../assets/logo-text.svg" (click)="toRoute('')" />
    <div id="hamburger" (click)="toggleNavbar()">
      <img src="{{ hamburgerIcon }}" alt="Hamburger Icon" />
    </div>
  </div>
  <div class="part links">
    <div (click)="toRoute('')">Home</div>
    <div (click)="toRoute('events')">Alle Events</div>
    <div (click)="toRoute('calendar')">Kalender</div>
    <div (click)="toRoute('organizers')">Alle Veranstalter</div>
    <div (click)="toRoute('new-organizer')">Veranstalter eintragen</div>
    <div>
      <button mat-raised-button color="accent" id="newEventButton" (click)="toRoute('new')">Event eintragen</button>
    </div>
  </div>
  <div class="part overflow"></div>
</mat-toolbar>
