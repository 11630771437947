<div id="bg">
  <div style="--background-image-url: url('{{apiEndpoint}}{{organizer.image}}')" id="img"></div>
  <div id="content">
    <div id="title">
      <div>{{organizer.name}}</div>
      <img class="verifiedImage" src="/assets/icons/verified.svg" *ngIf="organizer.is_verified" id="verifiedIcon" alt="verfiedIcon" />
    </div>
    <div id="infos">
      <div class="texticon">
        <img src="/assets/icons/mapicon.svg" alt="Map Icon" />
        <div>{{organizer.location_name}}</div>
      </div>
    </div>
    <div id="text">
      {{organizer.description}}
    </div>
  </div>
  <div id="dot" [ngClass]="{'pending': status == 1, 'active': status == 2}" (click)="toggle()">
    <mat-icon *ngIf="status == 2">check</mat-icon>
    <mat-icon *ngIf="status == 1" class="rotate">sync</mat-icon>
    <mat-icon *ngIf="status == 0">close</mat-icon>
  </div>
</div>