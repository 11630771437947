<h2>Featured Event</h2>
<div id="eventPreview">
  <app-event-featured [event]="this.currentFeaturedEvent" [newPage]="true"></app-event-featured>
</div>
<div id="selector">
  <div>
    Featured Event ändern:
  </div>

  <div>
    <mat-form-field>
      <mat-label>Auswählen...</mat-label>
      <input type="text"
             matInput
             [formControl]="selectControl"
             [matAutocomplete]="auto"
             >
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectionChanged($event)">
        @for (option of filteredOptions | async; track option) {
          <mat-option [value]="option.name" [id]="option | json">{{option.name}}</mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div>
    <button mat-raised-button color="accent" (click)="save()" [disabled]="!saveButtonActive()">Speichern</button>
  </div>
</div>