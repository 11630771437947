import { Component } from '@angular/core';
import { LoadingStateService } from './loading-state.service';
import { AuthService } from './auth.service';
import { Router, Event, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'twenty-one-events';
  adminNavbar = false;
  
  constructor(public loadingStateService: LoadingStateService, private authService: AuthService, private router: Router) {}

  ngOnInit() {
    const adminLinks = ['/admin-dashboard'];
    this.loadingStateService.activate();
    this.authService.init();
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.adminNavbar = adminLinks.some((link) => event.url.startsWith(link));
      }
    });
  }
}
