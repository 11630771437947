import { Component, Input } from '@angular/core';
import { EventDto, dummyEventDto } from 'src/assets/event.dto';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-event-featured',
  templateUrl: './event-featured.component.html',
  styleUrls: ['./event-featured.component.scss']
})
export class EventFeaturedComponent {
  @Input() event: EventDto = dummyEventDto
  @Input() newPage = false;

  apiEndpoint = this.api.getEndpoint();

  constructor(private api: ApiService, private router: Router) { }

  toEventDetailView() {
    if (!this.newPage) {
      this.router.navigate(['/event', this.event.id]);
    } else {
      window.open(`/event/${this.event.id}`, '_blank');
    }
  }
}
