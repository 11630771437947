import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrl: './image-cropper.component.scss',
})
export class ImageCropperComponent {
  croppedImage: any;
  filepath: string = '';
  loading: boolean = true;

  constructor(public dialogRef: MatDialogRef<ImageCropperComponent>, @Inject(MAT_DIALOG_DATA) public imageChangedEvent: any, private snackBar: MatSnackBar) {}

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.filepath = event.target.files[0].name;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event;
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    this.loading = false;
  }
  loadImageFailed() {
    // show message
    this.snackBar.open('Fehler beim Öffnen des Bildes', '', {
      duration: 3000,
      panelClass: ['red-snackbar'],
    });
    // close dialog
    this.dialogRef.close();
  }
}
