<div id="wrapper" (click)="toEventDetailView()">
  <div id="leftside">
    <img src="{{apiEndpoint}}{{event.image}}" alt="" />
  </div>
  <div id="rightside">
      <h3>{{event.name}}</h3>
      <div id="descr">
          {{event.short_description}}
      </div>
  </div>
</div>
