<mat-toolbar [ngClass]="{ open: navbarOpen, opened: readyOpen }">
  <div class="part logo">
    <img src="../../assets/logo-text-management.svg" (click)="toRoute('admin-dashboard/validate-events')" />
    <div id="hamburger" (click)="toggleNavbar()">
      <img src="{{ hamburgerIcon }}" alt="Hamburger Icon" />
    </div>
  </div>
  <div class="part links">
    <div [matMenuTriggerFor]="menu" id="account">
      <div id="icon">
        <mat-icon>account_circle</mat-icon>
      </div>
      <div id="username">{{ username }}</div>
    </div>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="logout()">
        <mat-icon>logout</mat-icon>
        <span>Ausloggen</span>
      </button>
    </mat-menu>
  </div>
</mat-toolbar>
