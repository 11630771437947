import { Component } from '@angular/core';
import { EventDto } from 'src/assets/event.dto';
import { ApiService } from '../api.service';
import { LoadingStateService } from '../loading-state.service';
import { FormControl } from '@angular/forms';
import { Category } from 'src/assets/category.dto';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent {
  events: EventDto[] = [];
  selectedCategorys = new FormControl('');
  categoryList: Category[] = [];
  searchSuggestions: string[] = ['Eröffnung', 'Freibier', 'Eintritt gratis', 'Flohmarkt', 'Austellung', 'Rave', 'E-Sport', 'Heuriger'];
  searchQuery = '';
  searchTimout: ReturnType<typeof setTimeout> | undefined;
  sliderValue: number = 101;
  lat: number = 0;
  long: number = 0;

  constructor(private api: ApiService, private loadingStateService: LoadingStateService) {}

  async ngOnInit() {
    await this.api.fetchVerifiedEvents();
    this.events = this.api.getVerifiedEvents();
    await this.api.fetchCategories();
    this.categoryList = this.api.getCategories();
    this.loadingStateService.deactivate();
  }

  formatLabel(value: number): string {
    if (value === 101) return '>100km';
    return `${value}km`;
  }

  setSearchQuery(query: string) {
    this.searchQuery = query;
    this.runSearch();
  }

  async runSearch() {
    await this.getLocation();
    const selectedCategorys = this.selectedCategorys.value as unknown as string[];
    const newEvent: EventDto[] = await this.api.getEventsByQuery(this.lat, this.long, this.sliderValue === 101 ? 50000 : this.sliderValue, selectedCategorys, this.searchQuery.trim());
    this.events = newEvent;
  }

  resetFilter() {
    this.selectedCategorys = new FormControl('');
    this.sliderValue = 101;
    this.runSearch();
  }

  resetSearch() {
    this.searchQuery = '';
    this.runSearch();
  }

  autoSearcher() {
    //reset timeout
    if (this.searchTimout) clearTimeout(this.searchTimout);
    this.searchTimout = setTimeout(() => {
      this.runSearch();
    }, 250);
  }

  async getLocation() {
    if (this.lat !== 0 && this.long !== 0) return;
    const response = await fetch('https://ipapi.co/json');
    const result = JSON.parse(await response.text());
    this.lat = result.latitude;
    this.long = result.longitude;
  }

  categoryChange() {
    this.runSearch();
  }
}
