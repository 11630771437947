import { Component, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { pluck } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';
import { Category } from 'src/assets/category.dto';
import { ApiService } from '../api.service';
import { LoadingStateService } from '../loading-state.service';
import { NewEvent } from 'src/assets/event.dto';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

interface previewTableElement {
  key: string;
  value: string;
}

@Component({
  selector: 'app-add-event-form',
  templateUrl: './add-event-form.component.html',
  styleUrl: './add-event-form.component.scss',
})
export class AddEventFormComponent {
  @ViewChild('stepper', { static: false }) private stepper: MatStepper | undefined;
  visitedPages: number[] = [];

  tomorrowDate = new Date(new Date().setDate(new Date().getDate() + 1));
  duration: string = '';

  @ViewChild('imageupload', { static: false }) imageUploadInput: ElementRef | undefined;

  organizers: string[] = [];
  filteredOrganizers: Observable<string[]> = new Observable<string[]>();
  organizerCrtl = new FormControl('');
  filepath: string = '';

  categories: Category[] = [];

  imageSrc: SafeUrl = '';

  previewTableData: previewTableElement[] = [];
  previewTableColumns: string[] = ['key', 'value'];

  generalGroup = this.formBuilder.group({
    eventname: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
    description: ['', [Validators.required, Validators.maxLength(1000)]],
  });

  locationGroup = this.formBuilder.group({
    location: ['', Validators.required],
    address: ['', Validators.required],
    latitude: [-1, Validators.required],
    longitude: [-1, Validators.required],
  });

  timeGroup = this.formBuilder.group({
    startDate: [null, Validators.required],
    endDate: [null, Validators.required],
    startTime: ['', Validators.required],
    endTime: ['', Validators.required],
  });

  contentGroup = this.formBuilder.group({
    description: ['', [Validators.required, Validators.maxLength(6000)]],
    category: ['', Validators.required],
    locationLink: ['', [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'), Validators.maxLength(200)]],
    organizer: [-1],
    croppedImage: [new Blob()],
  });

  createButtonActive: boolean = true;

  constructor(private formBuilder: FormBuilder, public dialog: MatDialog, private api: ApiService, private sanitizer: DomSanitizer, private router: Router, private loadingStateService: LoadingStateService, private snackBar: MatSnackBar) {}

  async ngOnInit() {
    await this.api.fetchCategories();
    this.categories = this.api.getCategories();
    await this.api.fetchVerifiedOrganizers();
    this.organizers = this.api.organizers.map((organizer) => organizer.name);
    this.filteredOrganizers = this.organizerCrtl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterOrganizers(value || '')),
    );
    this.loadingStateService.deactivate();
  }

  ngAfterViewInit() {
    this.stepper?.selectionChange.pipe(pluck('selectedIndex')).subscribe((res: number) => {
      if (!this.visitedPages.includes(res)) {
        this.visitedPages.push(res);
      }
      if (res === 4) {
        this.generatePreviewTable();
      }
    });
  }

  mapLocationChanged(e: { latitude: number; longitude: number; address: string }) {
    this.locationGroup.controls['latitude'].setValue(e.latitude);
    this.locationGroup.controls['longitude'].setValue(e.longitude);
    this.locationGroup.controls['address'].setValue(e.address.replaceAll(', ', '\n'));
  }

  fileChangeEvent(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.files === null) {
      return;
    }

    this.filepath = target.files[0].name;
    const dialogRef = this.dialog.open(ImageCropperComponent, {
      data: event,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.contentGroup.controls['croppedImage'].setValue(result.blob);
      this.imageSrc = this.sanitizer.bypassSecurityTrustUrl(result.objectUrl);
    });
  }

  imageIsUnchanged() {
    return this.imageSrc === '';
  }

  calculateStarttimeMax() {
    if (this.timeGroup.controls['endTime'].value !== '' && this.timeGroup.controls['startDate'].value === null && this.timeGroup.controls['endDate'].value === null) {
      const startDate = this.timeGroup.controls['startDate'].value! as Date;
      const endDate = this.timeGroup.controls['endDate'].value! as Date;

      if (startDate?.toISOString() === endDate?.toISOString()) {
        return this.timeGroup.controls['endTime'].value;
      }
    }
    return '23:59';
  }

  calculateEndtimeMin() {
    const startDate = this.timeGroup.controls['startDate'].value! as Date;
    const endDate = this.timeGroup.controls['endDate'].value! as Date;
    const startTime = this.timeGroup.controls['startTime'].value;
    return startTime !== '' && startDate?.toISOString() === endDate?.toISOString() ? startTime : '00:00';
  }

  calculateDuration() {
    let returnString = '';

    if (this.timeGroup.controls['startDate'].value === null || this.timeGroup.controls['endDate'].value === null) {
      return false;
    }

    const startDate = this.timeGroup.controls['startDate'].value as Date;
    const endDate = this.timeGroup.controls['endDate'].value as Date;
    const startTime = this.timeGroup.controls['startTime'].value;
    const endTime = this.timeGroup.controls['endTime'].value;

    let startDateTimestamp;
    let endDateTimestamp;

    if (!startTime || !endTime) {
      startDateTimestamp = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()).getTime();
      endDateTimestamp = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()).getTime();
    } else {
      startDateTimestamp = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), parseInt(startTime.split(':')[0]), parseInt(startTime.split(':')[1])).getTime();
      endDateTimestamp = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), parseInt(endTime.split(':')[0]), parseInt(endTime.split(':')[1])).getTime();
    }

    const diff = endDateTimestamp - startDateTimestamp;

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

    if (hours < 1 && days < 1) {
      return false;
    }

    if (days > 0) {
      returnString += days + 'T';
      if (hours > 0) {
        returnString += ' ';
      }
    }

    if (hours > 0) {
      returnString += hours + 'Std';
    }

    this.duration = returnString;
    return true;
  }

  private filterOrganizers(value: string): string[] {
    if (!value) {
      return this.organizers;
    }
    const filterValue = value.toLowerCase();

    return this.organizers.filter((option) => option.toLowerCase().includes(filterValue));
  }

  organizerSelected() {
    const organizerName = this.organizerCrtl.value;
    const organizer = this.api.organizers.find((organizer) => organizer.name === organizerName);
    this.contentGroup.controls['organizer'].setValue(organizer ? organizer.id : -1);
    if (!organizer) {
      this.organizerCrtl.setValue('');
    }
  }

  generatePreviewTable() {
    this.previewTableData = [];

    const datepipe: DatePipe = new DatePipe('de-DE');

    if (this.generalGroup.controls['eventname'].value) {
      this.previewTableData.push({ key: 'Eventname', value: this.generalGroup.controls['eventname'].value });
    }
    if (this.generalGroup.controls['description'].value) {
      this.previewTableData.push({ key: 'Kurzbeschreibung', value: this.generalGroup.controls['description'].value });
    }
    if (this.locationGroup.controls['location'].value) {
      this.previewTableData.push({ key: 'Veranstaltungsort', value: this.locationGroup.controls['location'].value });
    }
    if (this.locationGroup.controls['address'].value) {
      this.previewTableData.push({ key: 'Adresse', value: this.locationGroup.controls['address'].value });
    }

    if (this.timeGroup.controls['startDate'].value && this.timeGroup.controls['startTime'].value) {
      let start_time = this.timeGroup.controls['startDate'].value as Date;
      start_time.setHours(parseInt(this.timeGroup.controls['startTime'].value.split(':')[0]));
      start_time.setMinutes(parseInt(this.timeGroup.controls['startTime'].value.split(':')[1]));
      const formattedDate = datepipe.transform(start_time, 'dd.MM.yyyy HH:mm') as string;
      this.previewTableData.push({ key: 'Startdatum', value: formattedDate });
    }

    if (this.timeGroup.controls['endDate'].value && this.timeGroup.controls['endTime'].value) {
      let end_time = this.timeGroup.controls['endDate'].value as Date;
      end_time.setHours(parseInt(this.timeGroup.controls['endTime'].value.split(':')[0]));
      end_time.setMinutes(parseInt(this.timeGroup.controls['endTime'].value.split(':')[1]));
      const formattedDate = datepipe.transform(end_time, 'dd.MM.yyyy HH:mm') as string;
      this.previewTableData.push({ key: 'Enddatum', value: formattedDate });
    }

    if (this.contentGroup.controls['description'].value) {
      this.previewTableData.push({ key: 'Beschreibung', value: this.contentGroup.controls['description'].value });
    }
    if (this.contentGroup.controls['category'].value) {
      this.previewTableData.push({ key: 'Kategorie', value: this.contentGroup.controls['category'].value });
    }
    if (this.contentGroup.controls['locationLink'].value) {
      this.previewTableData.push({ key: 'Link', value: this.contentGroup.controls['locationLink'].value });
    }
    if (this.organizerCrtl.value) {
      this.previewTableData.push({ key: 'Veranstalter', value: this.organizerCrtl.value });
    }
  }

  async submitForm() {
    this.createButtonActive = false;
    this.snackBar.open('Event wird erstellt...', '', {
      panelClass: ['gray-snackbar'],
    });
    let organizer: number | null = null;
    let name = '';
    let short_description = '';
    let description = '';
    let category = '';
    let link: string | null = null;
    let start_time = new Date();
    let end_time = new Date();
    let location_name = '';
    let latitude = -1;
    let longitude = -1;
    let image: Blob | null = null;

    if (this.contentGroup.controls['organizer'].value != -1) {
      organizer = this.contentGroup.controls['organizer'].value;
    }
    if (this.generalGroup.controls['eventname'].value) {
      name = this.generalGroup.controls['eventname'].value;
    }
    if (this.generalGroup.controls['description'].value) {
      short_description = this.generalGroup.controls['description'].value;
    }
    if (this.contentGroup.controls['description'].value) {
      description = this.contentGroup.controls['description'].value;
    }
    if (this.imageSrc !== '') {
      image = this.contentGroup.controls['croppedImage'].value;
    }
    if (this.contentGroup.controls['category'].value) {
      category = this.contentGroup.controls['category'].value;
    }
    if (this.contentGroup.controls['locationLink'].value) {
      link = this.contentGroup.controls['locationLink'].value;
    }
    if (this.timeGroup.controls['startDate'].value) {
      start_time = this.timeGroup.controls['startDate'].value;
    }
    if (this.timeGroup.controls['startTime'].value) {
      start_time.setHours(parseInt(this.timeGroup.controls['startTime'].value.split(':')[0]));
      start_time.setMinutes(parseInt(this.timeGroup.controls['startTime'].value.split(':')[1]));
    }
    if (this.timeGroup.controls['endDate'].value) {
      end_time = this.timeGroup.controls['endDate'].value;
    }
    if (this.timeGroup.controls['endTime'].value) {
      end_time.setHours(parseInt(this.timeGroup.controls['endTime'].value.split(':')[0]));
      end_time.setMinutes(parseInt(this.timeGroup.controls['endTime'].value.split(':')[1]));
    }
    if (this.locationGroup.controls['location'].value) {
      location_name = this.locationGroup.controls['location'].value;
    }
    if (this.locationGroup.controls['latitude'].value) {
      latitude = this.locationGroup.controls['latitude'].value;
    }
    if (this.locationGroup.controls['longitude'].value) {
      longitude = this.locationGroup.controls['longitude'].value;
    }

    const event: NewEvent = {
      organizer: organizer,
      name: name,
      short_description: short_description,
      description: description,
      category: category,
      link: link,
      start_time: start_time,
      end_time: end_time,
      location_name: location_name,
      latitude: latitude,
      longitude: longitude,
    };

    if (await this.api.postEvent(event, image)) {
      this.snackBar.open('Event erfolgreich erstellt', '', {
        duration: 3000,
        panelClass: ['green-snackbar'],
      });
      this.router.navigate(['/']);
    } else {
      this.snackBar.open('Fehler beim Erstellen des Events', '', {
        duration: 3000,
        panelClass: ['red-snackbar'],
      });
    }
    this.createButtonActive = true;
  }
}
