import { EventDto } from "./event.dto";

export interface Organizer {
  id: number;
  event_count: number;
  next_event: EventDto | null;
  name: string;
  description: string;
  image: string;
  link: string;
  location_name: string;
  latitude: number;
  longitude: number;
  validated: boolean;
  is_verified: boolean;
  created: string;
  updated: string;
}

export const dummyOrganizer: Organizer = {
  id: 0,
  event_count: 0,
  next_event: null,  
  name: '',
  description: '',
  image: '',
  link: '',
  location_name: '',
  latitude: -1,
  longitude: -1,
  validated: false,
  is_verified: false,
  created: '',
  updated: '',
};

export interface NewOrganizer {
  name: string;
  description: string;
  link: string;
  location_name: string;
  latitude: number;
  longitude: number;
}