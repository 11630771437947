import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  constructor(private router: Router) {}
  toRoute(route: string) {
    const routerUrl = '/' + route;
    if (this.router.url !== routerUrl) {
      this.router.navigate(['/' + route]);
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
}


