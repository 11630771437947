import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { EventDto, dummyEventDto } from 'src/assets/event.dto';
import { ApiService } from '../api.service';
import { LoadingStateService } from '../loading-state.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  events: EventDto[] = [];
  featuredEvent: EventDto = dummyEventDto;
  specialEvent: EventDto = dummyEventDto;
  typewriterTexts: string[] = ['Events in deiner Nähe', 'Finde die besten Veranstaltungen', 'Plane dein Wochenende', 'Entdecke neue Locations', 'Nie wieder Langeweile'];
  typewriterCurrentText!: string;
  typewriterWidth = 12;
  typewriterCursorOffset = 10;
  typewriterCharacterWidth = 11.52;
  typewriterBlinking = true;
  loading = true;
  animation = true;

  constructor(private api: ApiService, private loadingStateService: LoadingStateService, private location: Location) {}

  async ngOnInit() {
    this.animation = !this.isBackRoute();
    if (this.animation) {
      setTimeout(() => {
        this.scheduleTypewriter();
      }, 1000);
    } else {
      this.scheduleTypewriter();
    }
    
    await this.loadEvents();
    this.loading = false;
    this.loadingStateService.deactivate();
  }

  isBackRoute(): boolean {
    return (this.location.getState() as any)?.navigationId > 1;
  }

  async loadEvents() {
    await this.api.fetchRecommendedEvents();
    this.featuredEvent = this.api.getFeaturedEvent() as EventDto;
    this.specialEvent = this.api.getSpecialEvent() as EventDto;
    this.events = this.api.getRecommendedEvents();
  }

  async scheduleTypewriter() {
    while (true) {
      await this.runAnimation();
      await this.sleep(2000);
    }
  }

  generateNewText() {
    let newText: string = '';
    do {
      newText = this.typewriterTexts[Math.floor(Math.random() * this.typewriterTexts.length)];
    } while (newText == this.typewriterCurrentText && this.typewriterTexts.length > 1);
    return newText;
  }

  async runAnimation() {
    const newText = this.generateNewText();
    this.typewriterCurrentText = '';
    this.typewriterWidth = newText.length * this.typewriterCharacterWidth;
    this.typewriterBlinking = true;
    for (let i = 0; i < newText.length; i++) {
      this.typewriterCursorOffset = i * this.typewriterCharacterWidth + 13;
      this.typewriterCurrentText += newText[i];
      await this.sleep(50);
    }
    await this.sleep(1500);
    this.typewriterBlinking = false;
  }

  async sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
