import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Organizer, dummyOrganizer } from 'src/assets/organizer.dto';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-edit-organizer',
  templateUrl: './edit-organizer.component.html',
  styleUrl: './edit-organizer.component.scss'
})
export class EditOrganizerComponent {
  @Input() organizer: Organizer = dummyOrganizer;
  @Input() status: number = 0;
  @Output() toggleEvent = new EventEmitter<void>();
  @Output() deleteEvent = new EventEmitter<void>();
  @Output() verifyEvent = new EventEmitter<void>();

  apiEndpoint = '';
  constructor(private api: ApiService) {
    this.apiEndpoint = this.api.getEndpoint();
  }

  toggle() {
    this.toggleEvent.emit();
  }

  deleteFn() {
    this.deleteEvent.emit();
  }

  verify() {
    this.verifyEvent.emit();
  }
}
