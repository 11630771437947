import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { EventsComponent } from './events/events.component';
import { EventDetailViewComponent } from './event-detail-view/event-detail-view.component';
import { ImprintComponent } from './imprint/imprint.component';
import { AddEventFormComponent } from './add-event-form/add-event-form.component';
import { CalendarComponent } from './calendar/calendar.component';
import { LoginComponent } from './login/login.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminDashboardSpecialEventComponent } from './admin-dashboard-special-event/admin-dashboard-special-event.component';
import { AdminDashboardFeaturedEventComponent } from './admin-dashboard-featured-event/admin-dashboard-featured-event.component';
import { AdminDashboardValidateEventsComponent } from './admin-dashboard-validate-events/admin-dashboard-validate-events.component';
import { AdminDashboardValidateOrganizersComponent } from './admin-dashboard-validate-organizers/admin-dashboard-validate-organizers.component';
import { AddOrganizerFormComponent } from './add-organizer-form/add-organizer-form.component';
import { AdminDashboardEditEventsComponent } from './admin-dashboard-edit-events/admin-dashboard-edit-events.component';
import { AdminDashboardEditOrganizersComponent } from './admin-dashboard-edit-organizers/admin-dashboard-edit-organizers.component';
import { OrganizersComponent } from './organizers/organizers.component';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
  { path: '', component: HomeComponent, title: '21Events | Home' },
  { path: 'events', component: EventsComponent, title: '21Events | Events' },
  { path: 'event/:id', component: EventDetailViewComponent, title: '21Events | Event' },
  { path: 'imprint', component: ImprintComponent, title: '21Events | Imprint' },
  { path: 'new', component: AddEventFormComponent, title: '21Events | Neues Event' },
  { path: 'new-organizer', component: AddOrganizerFormComponent, title: '21 Events | Neuer Veranstalter'},
  { path: 'calendar', component: CalendarComponent, title: '21Events | Kalender' },
  { path: 'login', component: LoginComponent, title: '21Events | Login' },
  { path: 'organizers', component: OrganizersComponent, title: '21Events | Veranstalter' },
  {
    path: 'admin-dashboard',
    component: AdminDashboardComponent,
    title: '21Events | Admin Dashboard',
    children: [
      {
        path: '',
        redirectTo: 'validate-events',
        pathMatch: 'full',
      },
      {
        path: 'validate-events',
        component: AdminDashboardValidateEventsComponent,
      },
      {
        path: 'edit-events',
        component: AdminDashboardEditEventsComponent,
      },
      {
        path: 'validate-organizers',
        component: AdminDashboardValidateOrganizersComponent,
      },
      {
        path: 'edit-organizers',
        component: AdminDashboardEditOrganizersComponent,
      },
      {
        path: 'specialevent',
        component: AdminDashboardSpecialEventComponent,
      },
      {
        path: 'featuredevent',
        component: AdminDashboardFeaturedEventComponent,
      },
    ],
  },
  { path: '**', component: NotFoundComponent, title: '21Events | Nicht gefunden' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
