<mat-stepper labelPosition="bottom" [linear]="true" #stepper>
  <mat-step [stepControl]="generalGroup">
    <form [formGroup]="generalGroup">
      <ng-template matStepLabel>Allgemein</ng-template>
      <div class="formGroup">
        <div class="leftContent">
          <mat-form-field>
            <mat-label>Eventname</mat-label>
            <input matInput formControlName="eventname" />
          </mat-form-field>
          <br /><br />
          <mat-form-field>
            <mat-label>Kurzbeschreibung</mat-label>
            <textarea id="shortText" matInput formControlName="description"></textarea>
          </mat-form-field>

          <div class="nextBtn">
            <button color="accent" [disabled]="!generalGroup.valid" mat-raised-button matStepperNext>Nächster Schritt</button>
          </div>
        </div>
        <div class="rightContent">
          <div class="image general"></div>
        </div>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="locationGroup">
    <form [formGroup]="locationGroup">
      <ng-template matStepLabel>Ort</ng-template>
      <div id="locationStep" class="formGroup">
        <div class="leftContent">
          <mat-form-field id="location">
            <mat-label>Veranstaltungsort</mat-label>
            <input matInput formControlName="location" />
          </mat-form-field>

          <br /><br />

          <mat-card>
            <mat-card-header>
              <mat-card-title class="cardTitle">Adresse</mat-card-title>
            </mat-card-header>
            <mat-card-content *ngIf="!this.locationGroup.controls['address'].value">Wähle einen gültigen Standort auf der Karte!</mat-card-content>
            <ng-container *ngIf="this.locationGroup.controls['address'].value">
              <mat-card-content *ngFor="let line of this.locationGroup.controls['address'].value.split('\n')">{{ line }}</mat-card-content>
            </ng-container>
          </mat-card>
          <input formControlName="address" type="hidden" />

          <div class="nextBtn">
            <button color="accent" [disabled]="!locationGroup.valid" mat-raised-button matStepperNext>Nächster Schritt</button>
          </div>
        </div>

        <div class="rightContent">
          <app-location-picker-map id="map" (locationPicked)="mapLocationChanged($event)" *ngIf="visitedPages.includes(1)" />
        </div>
      </div>
    </form>
  </mat-step>

  <mat-step [stepControl]="timeGroup">
    <form [formGroup]="timeGroup">
      <ng-template matStepLabel>Datum</ng-template>
      <div id="timeDateStep" class="formGroup">
        <div class="leftContent">
          <h2>Start</h2>
          <div class="timeDateGroup">
            <div>
              <mat-form-field color="primary" (click)="startDatepicker.open()">
                <mat-label>Startdatum</mat-label>
                <input matInput [matDatepicker]="startDatepicker" formControlName="startDate" [min]="tomorrowDate" [max]="this.timeGroup.controls['endDate'].value" />
                <mat-datepicker-toggle matIconSuffix [for]="startDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #startDatepicker></mat-datepicker>
              </mat-form-field>
            </div>

            <div>
              <ngx-mat-timepicker #startTimepicker color="primary"></ngx-mat-timepicker>
              <mat-form-field color="primary" (click)="startTimepicker.open()">
                <mat-label>Startzeit</mat-label>
                <input matInput type="text" formControlName="startTime" [ngxMatTimepicker]="startTimepicker" [format]="24" max="{{ calculateStarttimeMax() }}" readonly />
                <button matSuffix mat-icon-button aria-label="date">
                  <mat-icon>schedule</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </div>

          <br />
          <h2>Ende</h2>
          <div class="timeDateGroup">
            <div>
              <mat-form-field color="primary" (click)="endDatepicker.open()">
                <mat-label>Enddatum</mat-label>
                <input matInput [matDatepicker]="endDatepicker" formControlName="endDate" [min]="this.timeGroup.controls['startDate'].value" [min]="tomorrowDate" />
                <mat-datepicker-toggle matIconSuffix [for]="endDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #endDatepicker></mat-datepicker>
              </mat-form-field>
            </div>

            <div>
              <ngx-mat-timepicker #endTimepicker color="primary"></ngx-mat-timepicker>
              <mat-form-field color="primary" (click)="endTimepicker.open()">
                <mat-label>Endzeit</mat-label>
                <input matInput type="text" formControlName="endTime" [ngxMatTimepicker]="endTimepicker" [format]="24" min="{{ calculateEndtimeMin() }}" readonly />
                <button matSuffix mat-icon-button aria-label="date">
                  <mat-icon>schedule</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </div>

          <div class="nextBtn">
            <button color="accent" [disabled]="!timeGroup.valid" mat-raised-button matStepperNext>Nächster Schritt</button>
          </div>
        </div>

        <div class="rightContent">
          <div class="image date" [ngStyle]="{ filter: calculateDuration() ? 'brightness(.7)' : '' }"></div>
          <div *ngIf="calculateDuration()" class="duration">{{ duration }}</div>
        </div>
      </div>
    </form>
  </mat-step>

  <mat-step [stepControl]="contentGroup">
    <form [formGroup]="contentGroup">
      <ng-template matStepLabel>Inhalt</ng-template>
      <div id="contentStep" class="formGroup">
        <div class="leftContent">
          <mat-form-field>
            <mat-label>Beschreibung (Markdown unterstützt)</mat-label>
            <textarea id="longText" matInput formControlName="description" cdkTextareaAutosize></textarea>
          </mat-form-field>

          <br /><br />

          <mat-form-field>
            <mat-label>Kategorie</mat-label>
            <mat-select formControlName="category">
              @for (item of categories; track item) {
              <mat-option [value]="item.value">{{ item.name }}</mat-option>
              }
            </mat-select>
          </mat-form-field>

          <br /><br />

          <mat-form-field>
            <mat-label>Link des Events</mat-label>
            <input matInput formControlName="locationLink" />
          </mat-form-field>

          <br /><br />

          <form>
            <mat-form-field>
              <mat-label>Veranstalter</mat-label>
              <input type="text" aria-label="Number" matInput [formControl]="organizerCrtl" [matAutocomplete]="auto" />
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="this.organizerSelected()" (closed)="this.organizerSelected()">
                @for (option of filteredOrganizers | async; track option) {
                <mat-option [value]="option">{{ option }}</mat-option>
                }
              </mat-autocomplete>
            </mat-form-field>
            <div>
              <span>Veranstalter nicht gefunden? </span><a href="/new-organizer" target="_blank">Hier</a><span> kannst du ihn eintragen</span>
            </div>
          </form>

          <div class="nextBtn">
            <button color="accent" [disabled]="!contentGroup.valid" mat-raised-button matStepperNext>Nächster Schritt</button>
          </div>
        </div>

        <div class="rightContent">
          <button mat-raised-button color="accent" (click)="this.imageUploadInput?.nativeElement.click()" id="uploadImageBtn">Bild hochladen</button>
          <input hidden type="file" #imageupload (change)="fileChangeEvent($event)" accept=".jpg,.jpeg,.png,.gif,.tiff,.pdf,.webp" required />
          <span id="filename">{{ filepath }}</span>
          <br /><br />
          <img *ngIf="!imageIsUnchanged()" [src]="this.imageSrc" class="croppedImage" />
          <div *ngIf="imageIsUnchanged()" class="splashImage"></div>
        </div>
      </div>
    </form>
  </mat-step>

  <mat-step>
    <ng-template matStepLabel>Überprüfung</ng-template>
    <h1>Fertig!</h1>
    <p>Bitte überprüfe deine eingegebenen Daten noch ein letztes Mal.</p>
    <p>Nach dem Erstellen muss dein Event noch von einem <strong>Moderator freigeschaltet</strong> werden.</p>

    <table mat-table [dataSource]="previewTableData" class="mat-elevation-z8">
      <ng-container matColumnDef="key">
        <th mat-header-cell *matHeaderCellDef>key</th>
        <td mat-cell *matCellDef="let element"> {{element.key}} </td>
      </ng-container>
      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef>value</th>
        <td mat-cell *matCellDef="let element"> {{element.value}} </td>
      </ng-container>
      <tr mat-row *matRowDef="let row; columns: previewTableColumns;"></tr>
    </table>
    
    <div id="createEventContainer">
      <button mat-raised-button color="accent" (click)="submitForm()" [disabled]="!contentGroup.valid || !createButtonActive">Event erstellen</button>
    </div>
  </mat-step>
</mat-stepper>
