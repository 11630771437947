import { Component } from '@angular/core';
import { LoadingStateService } from '../loading-state.service';
import { EventDto } from 'src/assets/event.dto';
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-admin-dashboard-edit-events',
  templateUrl: './admin-dashboard-edit-events.component.html',
  styleUrl: './admin-dashboard-edit-events.component.scss',
})
export class AdminDashboardEditEventsComponent {
  constructor(private loadingStateService: LoadingStateService, private auth: AuthService, private api: ApiService, public dialog: MatDialog) {}
  events: EventDto[] = [];
  status: number[] = [];
  loading: boolean = true;

  async ngOnInit() {
    this.events = await this.api.getAllEvents();
    this.status = this.events.map((event) => (event.validated ? 2 : 0));
    this.loadingStateService.deactivate();
    this.loading = false;
  }

  async toggleEvent(index: number) {
    if (this.status[index] === 1) return;
    const newStatus = this.status[index] === 0 ? 2 : 0;
    this.status[index] = 1;
    await this.auth.toggleEventValidation(this.events[index].id);
    this.status[index] = newStatus;
    this.api.fetchVerifiedEvents(true);
  }

  async deleteEvent(index: number) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: false,
    });
    dialogRef.componentInstance.title = 'Event löschen?';
    dialogRef.componentInstance.text = `Willst du das Event "${this.events[index].name}" wirklich löschen?`;

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === true) {
        await this.auth.deleteEvent(this.events[index].id);
        this.events.splice(index, 1);
        this.status.splice(index, 1);
        this.api.fetchVerifiedEvents(true);
      }
    });
  }

  async verifyEvent(index: number) {
    await this.auth.toggleEventVerification(this.events[index].id);
    this.events[index].is_verified = !this.events[index].is_verified;
    this.api.fetchVerifiedEvents(true);
  }
}
