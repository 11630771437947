import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EventDto, NewEvent } from 'src/assets/event.dto';
import { Category } from 'src/assets/category.dto';
import { NewOrganizer, Organizer } from 'src/assets/organizer.dto';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  endpoint = 'https://api.twenty-one-events.htl-perg.ac.at';

  recommendedEvents: EventDto[] = [];
  featuredEvent: EventDto | undefined;
  specialEvent: EventDto | undefined;
  categories: Category[] = [];
  organizers: Organizer[] = [];

  events: EventDto[] = [];

  constructor(private http: HttpClient) {}

  getEndpoint(): string {
    return this.endpoint;
  }

  async fetchRecommendedEvents(forceRefetch: boolean = false) {
    if (this.featuredEvent !== undefined && this.specialEvent !== undefined && this.recommendedEvents.length > 0 && !forceRefetch) return;
    const events: EventDto[] = (await this.http.get(`${this.endpoint}/events/recommended/`).toPromise()) as EventDto[];
    this.featuredEvent = events.shift() as EventDto;
    this.specialEvent = events.shift() as EventDto;
    this.recommendedEvents = events as EventDto[];
  }

  getFeaturedEvent(): EventDto | undefined {
    return this.featuredEvent;
  }

  getSpecialEvent(): EventDto | undefined {
    return this.specialEvent;
  }

  getRecommendedEvents(): EventDto[] {
    return this.recommendedEvents;
  }

  async fetchVerifiedEvents(forceRefetch: boolean = false) {
    if (this.events.length > 0 && !forceRefetch) return;
    this.events = (await this.http.get(`${this.endpoint}/events/?validated`).toPromise()) as EventDto[];
  }

  async getAllEvents() {
    return (await this.http.get(`${this.endpoint}/events/`).toPromise()) as EventDto[];
  }

  getVerifiedEvents(): EventDto[] {
    return this.events as EventDto[];
  }

  async getEventsByQuery(lat: number, long: number, radius: number, categories: string[], search_query: string) {
    const categoryString = categories.length !== 0 ? `["${categories.join('","')}"]` : '""';
    const requestData = `{"latitude": ${lat},"longitude": ${long},"radius": ${radius},"category": ${categoryString},"search_query": ${search_query.trim().length === 0 ? '""' : `"${search_query}"`}}`;
    
    const headers = { 'Content-Type': 'application/json' };
    return (await this.http.post(`${this.endpoint}/events/filter/`, requestData, { headers }).toPromise()) as EventDto[];
  }

  async fetchCategories() {
    if (this.categories.length > 0) return;
    this.categories = (await this.http.get(`${this.endpoint}/categories/`).toPromise()) as Category[];
  }

  getCategories(): Category[] {
    return this.categories;
  }

  async postEvent(event: NewEvent, image: Blob | null = null): Promise<Boolean> {
    const formData = new FormData();

    formData.append('name', event.name);
    formData.append('short_description', event.short_description);
    formData.append('description', event.description);
    formData.append('category', event.category);
    formData.append('start_time', event.start_time.toISOString());
    formData.append('end_time', event.end_time.toISOString());
    formData.append('location_name', event.location_name);
    formData.append('latitude', event.latitude.toString());
    formData.append('longitude', event.longitude.toString());

    if (event.organizer) {
      formData.append('organizer', event.organizer?.toString());
    }

    if (event.link) {
      formData.append('link', event.link);
    }

    if (image != null) {
      formData.append('image', image, this.generateUUID() + '.jpg');
    }

    try {
      await this.http.post(`${this.endpoint}/events/`, formData).toPromise();
    } catch (error) {
      return false;
    }

    return true;
  }

  async postOrganizer(organizer: NewOrganizer, image: Blob | null = null): Promise<boolean> {
    const formData = new FormData();

    formData.append('name', organizer.name);
    formData.append('description', organizer.description);
    formData.append('link', organizer.link);
    formData.append('location_name', organizer.location_name);
    formData.append('latitude', organizer.latitude.toString());
    formData.append('longitude', organizer.longitude.toString());

    if (image != null) {
      formData.append('image', image, this.generateUUID() + '.jpg');
    }

    try {
      await this.http.post(`${this.endpoint}/organizers/`, formData).toPromise();
    } catch (error) {
      return false;
    }

    return true;
  }

  generateUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  async fetchVerifiedOrganizers(forceRefetch: boolean = false) {
    if (this.organizers.length > 0 && !forceRefetch) return;
    this.organizers = (await this.http.get(`${this.endpoint}/organizers/?validated`).toPromise()) as Organizer[];
  }

  async getAllOrganizers() {
    return (await this.http.get(`${this.endpoint}/organizers/`).toPromise()) as Organizer[];
  }

  getVerifiedOrganizers(): Organizer[] {
    return this.organizers;
  }

  getEventById(id: number): EventDto | undefined {
    return this.events.find((event) => event.id === id);
  }

  getOrganizerById(id: number): Organizer | undefined {
    return this.organizers.find((organizer) => organizer.id === id);
  }

  async getEventsByRadius(latitude: number, longitude: number, radius: number): Promise<EventDto[]> {
    const formData = new FormData();

    formData.append('latitude', latitude.toString());
    formData.append('longitude', longitude.toString());
    formData.append('radius', radius.toString());

    try {
      const response = await this.http.post(`${this.endpoint}/events/radius/`, formData).toPromise();
      return response as EventDto[];
    } catch (error) {
      return [];
    }
  }
}
