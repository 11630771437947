<div
  id="strip" [ngClass]="{animation : animation}" *ngIf="!loading">
  <div id="stripHeadline" [ngClass]="{animation : animation}">21Events</div>
  <div id="typewriterHeadline" [ngClass]="{animation : animation}" style="--typewriter-width: {{typewriterWidth}}px; --typewriter-cursor-offset: {{typewriterCursorOffset}}px">
    <div id="text">{{ typewriterCurrentText }}</div>
    <div id="cursor" [ngClass]="{blink : typewriterBlinking}"></div>
  </div>
</div>
<div id="eventList" *ngIf="!loading" [ngClass]="{animation : animation}">
  <h2>Empfehlungen</h2>
  <div id="events">
    <app-event-featured id="featuredEvent" class="event" [event]="featuredEvent"></app-event-featured>
    <app-event-special id="specialEvent" class="event" [event]="specialEvent"></app-event-special>

      <app-event-normal
      *ngFor="let event of events; let i = index"
      [event]="event"
      [id]="'event' + i"
      class="event"
      ></app-event-normal>
  </div>
</div>
