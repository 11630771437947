<div id="box">
  <div id="background">
    <div id="organizerPic" style="--background-image-url: url('{{ apiEndpoint }}{{ organizer.image }}')"></div>
    <div id="rightBox">
      <div id="organizerInfo">
        <div id="name">{{ organizer.name }}</div>
        <div id="place">
          <img src="/assets/icons/mapicon.svg" alt="Map Icon" />
          <h3 id="placeName">{{ organizer.location_name }}</h3>
        </div>

        <div id="whiteLine"></div>
        <div id="description">{{ organizer.description }}</div>
      </div>

      <div id="nextEventBox" *ngIf="organizer.next_event != null" (click)="routeToEvent()">
        <div id="nextEventContainer">
          <div id="nextEvent">Demnächst</div>
          <div id="row1">
            <div id="weekday">{{ dateWeekday(organizer.next_event.start_time) }}</div>
            <div id="nextEventName">{{ organizer.next_event.name }}</div>
          </div>
          <div id="row2">
            <div id="nextEventDate">{{ organizer.next_event.start_time | date : 'dd.MM.yy' }}</div>
            <div id="nextEventLocation">
              <img id="locationIcon" src="/assets/icons/location_on.svg" alt="loactionIcon" />
              <div id="nextEventLocationName">{{organizer.next_event.location_name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
