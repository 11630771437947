import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  navbarOpen = false;
  readyOpen = false;
  blockNav = false;
  hamburgerIcon = '/assets/icons/hamburger.svg';

  constructor(private router: Router) {}

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async toggleNavbar() {
    if (this.blockNav) {
      return;
    }
    this.blockNav = true;
    if (this.navbarOpen) {
      await this.closeNavbar();
    } else {
      await this.openNavbar();
    }
    this.blockNav = false;
  }

  async closeNavbar() {
    this.hamburgerIcon = '/assets/icons/hamburger.svg';
    this.readyOpen = false;
    await this.delay(150);
    this.navbarOpen = false;
    this.blockNav = false;
  }

  async openNavbar() {
    this.hamburgerIcon = '/assets/icons/hamburgerclose.svg';
    this.navbarOpen = true;
    await this.delay(300);
    this.readyOpen = true;
    this.blockNav = false;
  }

  toRoute(route: string) {
    const routerUrl = '/' + route;
    if (this.router.url !== routerUrl) {
      this.router.navigate(['/' + route]);
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    this.closeNavbar();
  }
}
