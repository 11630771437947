<div id="wrapper">
  <div id="menu">
    <div class="menuentry" routerLink="validate-events" routerLinkActive="active">Events validieren</div>
    <div class="menuentry" routerLink="edit-events" routerLinkActive="active">Events bearbeiten</div>
    <div class="menuentry" routerLink="validate-organizers" routerLinkActive="active">Veranstalter validieren</div>
    <div class="menuentry" routerLink="edit-organizers" routerLinkActive="active">Veranstalter bearbeiten</div>
    <div class="menuentry" routerLink="featuredevent" routerLinkActive="active">Featured Event</div>
    <div class="menuentry" routerLink="specialevent" routerLinkActive="active">Special Event</div>
  </div>
  <div id="content">
    <router-outlet></router-outlet>
  </div>
</div>
