import { Component } from '@angular/core';
import { LoadingStateService } from '../loading-state.service';
import { EventDto } from 'src/assets/event.dto';
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-admin-dashboard-validate-events',
  templateUrl: './admin-dashboard-validate-events.component.html',
  styleUrl: './admin-dashboard-validate-events.component.scss',
})
export class AdminDashboardValidateEventsComponent {
  constructor(private loadingStateService: LoadingStateService, private auth: AuthService, private api: ApiService) {}
  events: EventDto[] = [];
  status: number[] = [];
  loading: boolean = true;
  
  async ngOnInit() {
    this.events = await this.auth.getUnvalidatedEvents();
    this.status = this.events.map(() => 0);
    this.loadingStateService.deactivate();
    this.loading = false;
  }

  async toggleEvent(index: number) {
    if (this.status[index] === 1) return;
    const newStatus = this.status[index] === 0 ? 2 : 0;
    this.status[index] = 1;
    await this.auth.toggleEventValidation(this.events[index].id);
    this.status[index] = newStatus;
    this.api.fetchVerifiedEvents(true);
  }
}
