import { Component } from '@angular/core';
import { LoadingStateService } from '../loading-state.service';

@Component({
  selector: 'app-imprint',
  standalone: true,
  imports: [],
  templateUrl: './imprint.component.html',
  styleUrl: './imprint.component.scss'
})
export class ImprintComponent {
  constructor(private loadingStateService: LoadingStateService) {}
  ngAfterViewInit() {
    this.loadingStateService.deactivate();
  }
}

