import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { EventDto, dummyEventDto } from 'src/assets/event.dto';
import { LoadingStateService } from '../loading-state.service';
import { dateWeekday, dateMonth } from 'src/assets/translaters';
import { Organizer, dummyOrganizer } from 'src/assets/organizer.dto';
import * as mapboxgl from 'mapbox-gl';
import { environment } from 'src/environments/environment';
import { Marked } from '@ts-stack/markdown';

interface Place {
  street: string;
  city: string;
  country: string;
}

@Component({
  selector: 'app-event-detail-view',
  templateUrl: './event-detail-view.component.html',
  styleUrl: './event-detail-view.component.scss',
})
export class EventDetailViewComponent {
  showNotFound: boolean = false;
  eventId: number = -1;
  event: EventDto | null = null;
  organizer: Organizer | null = null;

  place: Place = {
    street: '',
    city: '',
    country: '',
  };

  apiEndpoint = this.api.endpoint;
  descriptionMarkdown: string = '';

  constructor(private route: ActivatedRoute, private api: ApiService, public loadingStateService: LoadingStateService, private router: Router, private location: Location) {}

  async ngOnInit() {
    await this.loadEvent();

    if (!this.event) {
      this.loadingStateService.deactivate();
      this.showNotFound = true;
      return;
    }

    this.descriptionMarkdown = this.parseMarkdown(this.event.description);
    await this.loadOrganizer();
    const place = await this.getAdress(this.event.latitude, this.event.longitude);
    if (place) this.place = place;
    this.loadingStateService.deactivate();
  }

  async loadEvent() {
    await this.api.fetchVerifiedEvents();
    this.event = this.api.getEventById(Number(this.route.snapshot.paramMap.get('id')))!;
  }

  async loadOrganizer(): Promise<boolean> {
    if (!this.event) {
      return false;
    }
    if (!this.event.organizer) {
      return false;
    }
    await this.api.fetchVerifiedOrganizers();
    const organizerloaded = this.api.getOrganizerById(this.event.organizer);
    if (organizerloaded) {
      this.organizer = organizerloaded;
      return true;
    }
    return false;
  }

  dateWeekday(date: Date): string {
    return dateWeekday(date);
  }

  dateMonth(date: Date): string {
    return dateMonth(date);
  }

  async getAdress(latitude: number, longitude: number) {
    const coordinates = new mapboxgl.LngLat(longitude, latitude);
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${coordinates.lng},${coordinates.lat}.json?access_token=${environment.mapBoxApiKey}&language=de-AT&types=address&limit=1`;
    const response = await (await fetch(url)).json();
    try {
      const features: string[] = response.features[0].place_name.split(',');
      const street = features[0].trim();
      const city = features[1].trim();
      const country = features[2].trim();
      const place: Place = {
        street: street,
        city: city,
        country: country,
      };
      return place;
    } catch (error) {
      return undefined;
    }
  }

  parseMarkdown(text: string): string {
    let markdown = Marked.parse(text);
    markdown = markdown.replace(/<a /g, '<a target="_blank" ');
    return markdown;
  }

  toCalendar() {
    this.router.navigate(['/calendar']);
  }

  transformLink(link: string): string {
    let transformedLink = link.replace(/(^\w+:|^)\/\//, '');
    transformedLink = transformedLink.split('/')[0];
    return transformedLink;
  }

  goBack() {
    this.location.back();
  }

  isBackRoute(): boolean {
    return (this.location.getState() as any)?.navigationId > 1;
  }
}
