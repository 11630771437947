import { Component, Input } from '@angular/core';
import { EventDto, dummyEventDto } from 'src/assets/event.dto';
import { ApiService } from '../api.service';
import { dateWeekday } from 'src/assets/translaters';
import { Router } from '@angular/router';

@Component({
  selector: 'app-event-normal',
  templateUrl: './event-normal.component.html',
  styleUrls: ['./event-normal.component.scss'],
})
export class EventNormalComponent {
  @Input() event: EventDto = dummyEventDto;
  apiEndpoint = this.api.getEndpoint();

  constructor(private api: ApiService, private router: Router) {}

  dateWeekday(date: Date): string {
    return dateWeekday(date);
  }

  toEventDetailView(): void {
    this.router.navigate(['/event', this.event.id]);
  }
}
