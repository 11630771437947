import { Component } from '@angular/core';
import { LoadingStateService } from '../loading-state.service';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { MatDialog } from '@angular/material/dialog';
import { Organizer } from 'src/assets/organizer.dto';

@Component({
  selector: 'app-admin-dashboard-edit-organizers',
  templateUrl: './admin-dashboard-edit-organizers.component.html',
  styleUrl: './admin-dashboard-edit-organizers.component.scss'
})
export class AdminDashboardEditOrganizersComponent {
  constructor(private loadingStateService: LoadingStateService, private auth: AuthService, private api: ApiService, public dialog: MatDialog) {}
  organizers: Organizer[] = [];
  status: number[] = [];
  loading: boolean = true;

  async ngOnInit() {
    this.organizers = await this.api.getAllOrganizers();
    this.status = this.organizers.map((organizer) => (organizer.validated ? 2 : 0));
    this.loadingStateService.deactivate();
    this.loading = false;
  }

  async toggleEvent(index: number) {
    if (this.status[index] === 1) return;
    const newStatus = this.status[index] === 0 ? 2 : 0;
    this.status[index] = 1;
    await this.auth.toggleOrganizerValidation(this.organizers[index].id);
    this.status[index] = newStatus;
    this.api.fetchVerifiedOrganizers(true);
  }

  async deleteEvent(index: number) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: false,
    });
    dialogRef.componentInstance.title = 'Veranstalter löschen?';
    dialogRef.componentInstance.text = `Willst du den Veranstalter "${this.organizers[index].name}" wirklich löschen?`;

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === true) {
        await this.auth.deleteOrganizer(this.organizers[index].id);
        this.organizers.splice(index, 1);
        this.status.splice(index, 1);
        this.api.fetchVerifiedOrganizers(true);
      }
    });
  }

  async verifyEvent(index: number) {
    await this.auth.toggleOrganizerVerification(this.organizers[index].id);
    this.organizers[index].is_verified = !this.organizers[index].is_verified;
    this.api.fetchVerifiedOrganizers(true);
  }
}
