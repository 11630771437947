<div id="bg">
  <div style="--background-image-url: url('{{ apiEndpoint }}{{ event.image }}')" id="img"></div>
  <div id="content">
    <div id="title">{{ event.name }}</div>
    <div id="infos">
      <div class="texticon">
        <img src="/assets/icons/mapicon.svg" alt="Map Icon" />
        <div>{{ event.location_name }}</div>
      </div>
      <div class="texticon">
        <img src="/assets/icons/pace.svg" alt="Map Icon" />
        <div>{{ event.start_time | date : 'dd.MM.yyyy' }}</div>
      </div>
    </div>
    <div id="text">
      {{ event.short_description }}
    </div>
  </div>
  <div id="icons">
    <div id="verify" (click)="verify()">
      <img src="/assets/icons/verified.svg" [ngClass]="{ active: event.is_verified }" />
    </div>
    <div id="delete" (click)="deleteFn()">
      <img src="/assets/icons/delete-icon.svg" alt="" />
    </div>
    <div id="dot" [ngClass]="{ pending: status == 1, active: status == 2 }" (click)="toggle()">
      <mat-icon *ngIf="status == 2">check</mat-icon>
      <mat-icon *ngIf="status == 1" class="rotate">sync</mat-icon>
      <mat-icon *ngIf="status == 0">close</mat-icon>
    </div>
  </div>
</div>
