<mat-stepper labelPosition="bottom" [linear]="true" #stepper>
  <mat-step [stepControl]="generalGroup">
    <form [formGroup]="generalGroup">
      <ng-template matStepLabel>Allgemein</ng-template>
      <div class="formGroup">
        <div class="leftContent">
          <mat-form-field>
            <mat-label>Veranstaltername</mat-label>
            <input matInput formControlName="organizername" />
          </mat-form-field>
          <br /><br />
          <mat-form-field>
            <mat-label>Beschreibung</mat-label>
            <textarea id="shortText" matInput formControlName="description"></textarea>
          </mat-form-field>

          <div class="nextBtn">
            <button color="accent" [disabled]="!generalGroup.valid" mat-raised-button matStepperNext>Nächster Schritt</button>
          </div>
        </div>
        <div class="rightContent">
          <div class="image general"></div>
        </div>
      </div>
    </form>
  </mat-step>

  <mat-step [stepControl]="locationGroup">
    <form [formGroup]="locationGroup">
      <ng-template matStepLabel>Ort</ng-template>
      <div id="locationStep" class="formGroup">
        <div class="leftContent">
          <mat-form-field id="location">
            <mat-label>Location</mat-label>
            <input matInput formControlName="location" />
          </mat-form-field>

          <br /><br />

          <mat-card>
            <mat-card-header>
              <mat-card-title class="cardTitle">Adresse</mat-card-title>
            </mat-card-header>
            <mat-card-content *ngIf="!this.locationGroup.controls['address'].value">Wähle einen gültigen Standort auf der Karte!</mat-card-content>
            <ng-container *ngIf="this.locationGroup.controls['address'].value">
              <mat-card-content *ngFor="let line of this.locationGroup.controls['address'].value.split('\n')">{{ line }}</mat-card-content>
            </ng-container>
          </mat-card>
          <input formControlName="address" type="hidden" />

          <div class="nextBtn">
            <button color="accent" [disabled]="!locationGroup.valid" mat-raised-button matStepperNext>Nächster Schritt</button>
          </div>
        </div>

        <div class="rightContent">
          <app-location-picker-map id="map" (locationPicked)="mapLocationChanged($event)" *ngIf="visitedPages.includes(1)" />
        </div>
      </div>
    </form>
  </mat-step>

  <mat-step [stepControl]="contentGroup">
    <form [formGroup]="contentGroup">
      <ng-template matStepLabel>Infos</ng-template>
      <div id="contentStep" class="formGroup">
        <div class="leftContent">
          <mat-form-field>
            <mat-label>Link des Veranstalters</mat-label>
            <input matInput formControlName="locationLink" />
          </mat-form-field>

          <button mat-raised-button color="accent" (click)="this.imageUploadInput?.nativeElement.click()" id="uploadImageBtn">Bild hochladen</button>
          <input hidden type="file" #imageupload (change)="fileChangeEvent($event)" accept=".jpg,.jpeg,.png,.gif,.tiff,.pdf,.webp" required />
          <span id="filename">{{ filepath }}</span>
          <br />
          <img *ngIf="!imageIsUnchanged()" [src]="this.imageSrc" class="croppedImage" />
          <div *ngIf="imageIsUnchanged()" class="splashImage"></div>

          <div class="nextBtn">
            <button color="accent" [disabled]="!contentGroup.valid" mat-raised-button matStepperNext>Nächster Schritt</button>
          </div>
        </div>

        <div class="rightContent">
          <div class="image infos"></div>
        </div>
      </div>
    </form>
  </mat-step>

  <mat-step>
    <ng-template matStepLabel>Überprüfung</ng-template>
    <h1>Fertig!</h1>
    <p>Bitte überprüfe deine eingegebenen Daten noch ein letztes Mal.</p>
    <p>Nach dem Erstellen muss der Veranstalter noch von einem <strong>Moderator freigeschaltet</strong> werden.</p>

    <table mat-table [dataSource]="previewTableData" class="mat-elevation-z8">
      <ng-container matColumnDef="key">
        <th mat-header-cell *matHeaderCellDef>key</th>
        <td mat-cell *matCellDef="let element">{{ element.key }}</td>
      </ng-container>
      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef>value</th>
        <td mat-cell *matCellDef="let element">{{ element.value }}</td>
      </ng-container>
      <tr mat-row *matRowDef="let row; columns: previewTableColumns"></tr>
    </table>
    <div id="createOrganizerContainer">
      <button mat-raised-button color="accent" (click)="submitForm()" [disabled]="!contentGroup.valid || !createButtonActive">Veranstalter erstellen</button>
    </div>
  </mat-step>
</mat-stepper>
