import { Component, Input } from '@angular/core';
import { EventDto, dummyEventDto } from 'src/assets/event.dto';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-event-special',
  templateUrl: './event-special.component.html',
  styleUrls: ['./event-special.component.scss']
})
export class EventSpecialComponent {
  @Input() event: EventDto = dummyEventDto;

  apiEndpoint = this.api.getEndpoint();

  constructor(private api: ApiService, private router: Router) { }

  toEventDetailView(): void {
    this.router.navigate(['/event', this.event.id]);
  }
}
